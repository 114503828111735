<template>
  <div v-if="$hasFonction(user, 'Voir manifeste carte importateur')">
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div :hidden="hidden">
        <div class="d-flex justify-content-end">
          <b-button class="mr-2" variant="primary" @click="addsManifeste()"
          >Ajouter un manifeste</b-button
          >

          <!-- bouton export start -->
          <b-button
              variant="outline-primary"
              size="sm"
              @click="ExportFomatExcel()"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Exporter
          </b-button>
          <!-- bouton export end -->


          <!-- bouton export start -->
          <b-button
              variant="outline-primary"
              size="sm"
              class="ml-2"
              @click="ExportAllToFomatExcel()"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Tout Exporter
          </b-button>
          <!-- bouton export end -->

        </div>

        <b-card no-body title="" class="mt-1">
          <div class="container-filter my-2 mx-2 row">
            <!-- search start -->
            <div style="width: 20%" class="container-search-manifest-impor mr-3">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Recherche"
              >
              </b-form-input>
            </div>
            <!-- search end -->

            <!-- date start -->
            <div class="container-date-start mr-2" style="width: 21%">
                <b-form-input
                v-model="valueStart"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              @input="dateFiltre()"
              />
              <label>Date de début</label>
              <span
                class="text-danger"
                style="font-size: 0.9em; margin-top: 5px; margin-left: 8px"
              >
                {{ errorValueStart }}
              </span>
            </div>

            <div class="container-date-end mr-3" style="width: 21%">
                <b-form-input
                v-model="valueEnd"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              @input="dateFiltre()"
              />
              <label>Date de fin</label>
            </div>
            <!-- date end -->

            <!-- select importateur start -->
            <b-form-select
              v-model="selectedImportateur"
              :options="options"
              class="container-importateur col-2"
              @change="importateurFiltre()"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  style="color: #b4b7bd; text-align: center"
                  >-- Importateurs --</b-form-select-option
                >
              </template>
            </b-form-select>

            <div class="col-lg-2 ml-0">
                  <b-form-select
                              ref="status_filter"
                              v-model="selectedTrie"
                              :options="trieDate"
                              @change="changeTrie"
                              @input="filterValue"
                          >
                  </b-form-select>
                  <label>Trier Par Date</label>
            </div>
            <!-- select importateur end -->
          </div>

          <!-- table start -->
          <b-table
            v-model="ExcelManifestes"
            id="my-table"
            responsive
            :fields="headers"
            :items="manifestes"
            :per-page="perPage"
            hover
            striped
            @row-clicked="onRowClicked"
            :current-page="currentPage"
            :filter="filter"
            show-empty
            class="border-bottom position-relative"
          >
            <template #cell(date_arrivee)="data">
              <span>
                {{ data.item.date_arrivee ? data.item.date_arrivee : '----', }}
              </span>
            </template>

            <template #cell(prix_carte_imp)="data">
              {{ $thousandSeparator(data.item.prix_carte_imp) }}
            </template>

            <template #cell(nbr_vehicules)="data">
              <span>
                {{ data.item.nbr_vehicules ? data.item.nbr_vehicules : 0, }}
              </span>
            </template>

            <!-- iconAction start -->
            <template #cell(actions)="data">
              <div class="row">
                <div
                  v-if="
                    $hasFonction(
                      user,
                      'Réceptionner manifeste carte importateur'
                    )
                  "
                >
                  <b-button
                    :id="'button-2' + data.item.id"
                    v-if="!data.item.date_arrivee"
                    v-b-modal.modal-details-manifeste
                    size="sm"
                    variant="flat-warning"
                    class="btn-icon"
                    @click="editManifeste(data.item)"
                  >
                    <feather-icon icon="CheckCircleIcon" />
                  </b-button>
                  <b-tooltip
                    :target="'button-2' + data.item.id"
                    title="Receptionner"
                    variant="dark"
                  ></b-tooltip>
                </div>

                <div
                  v-if="
                    $hasFonction(user, 'Supprimer manifeste carte importateur')
                  "
                >
                  <b-button
                    :id="'button-3' + data.item.id"
                    v-if="!data.item.date_arrivee"
                    size="sm"
                    variant="flat-danger"
                    class="btn-icon"
                    @click="deleteManifeste(data.item)"
                    ref="button-3"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                  <!-- <b-tooltip
                    :target="'button-3' + data.item.id"
                    title="Supprimer"
                    variant="dark"
                  ></b-tooltip> -->
                </div>
              </div>
            </template>
            <!-- iconAction end -->

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2" v-if="totalRows != 0"> 1 - <span v-if="perPage >= totalRows">{{ totalRows }}</span> 
	              <span v-if="perPage < totalRows"> {{perPage}} </span> sur {{ totalRows }}
                </span>
            <span class="text-nowrap ml-2" v-if="totalRows == 0"> 0 éléments</span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>

        <!-- modal edit start  -->
        <b-modal
          id="modal-editManifeste"
          v-model="modalEditManifeste"
          title="Receptionner manifeste"
          @hidden="resetModalEditManifeste"
          hide-footer
          centered
        >
          <!-- form start -->
          <b-form
            class="mt-1"
            ref="editForm"
            @submit.stop.prevent="handleSubmit"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="date_arrivee"
                rules="required"
              >
                <b-form-datepicker
                  v-model="editedDate.date_arrivee"
                  placeholder="Date d'arrivée"
                  labelHelp="Utilisez les touches du curseur pour naviguer dans les dates du calendrier."
                  labelNoDateSelected="Aucune date sélectionnée"
                  labelResetButton="Annuler"
                  reset-button
                  :date-format-options="dateFormatOptions"
                  locale="fr"
                  :max="maxDate"
                  @input="resetInputDate()"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsDateArrivalModal
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="modalEditManifeste = false"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButton"
                variant="primary"
                class="mt-2"
                :disabled="isOperating"
                @click="handleOk"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
          <!-- form end -->
        </b-modal>
        <!-- modal edit end-->
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
        <b-button
          v-if="$hasFonction(user, 'Ajouter manifeste carte importateur')"
          variant="primary"
          size="sm"
          @click="addsManifeste()"
        >
          <b-icon-plus></b-icon-plus>
          AJOUTER
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BIconPlus,
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BTooltip,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker,
  BIconCircleFill,
  VBTooltip,
  BFormSelectOption,
  BIconArrowClockwise,
  BIconFileEarmarkArrowDownFill,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BTooltip,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BFormDatepicker,
    BIconCircleFill,
    BFormSelectOption,
    BIconArrowClockwise,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    ValidationProvider,
    DatePicker,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      dateFormatOptions: { day: "numeric", month: "long", year: "numeric" },
      options: [],
      etatButton: true,
      valueStart: null,
      valueEnd: null,
      errorValueStart: "",
      isOperating: false,
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      selectedImportateur: null,
      modalEditManifeste: false,
      perPage: 10,
      currentPage: 1,
      filter: "",
      trieDate: [
        { text: "Croissant", value: "croissant" },
        { text: "Decroissant", value: "decroissant" },
      ],
      selectedTrie: null,
      pageOptions: [10, 25, 50, 100, { value: 1000000, text: "Tout" }],
      headers: [
        {
          key: "numero_manif",
          label: "Numéro",
          sortable: true,
        },
        {
          key: "date_arrivee",
          label: "Date de réception",
          sortable: false,
          class: "text-center",
        },
        {
          key: "importateur.name",
          label: "Importateur",
          sortable: true,
        },
        {
          key: "navire.name",
          label: "Navire",
          sortable: true,
        },
        {
          key: "prix_carte_imp",
          label: "Prix de la carte importateur",
          sortable: true,
          class: "text-center",
        },
        {
          key: "nbr_vehicules",
          label: "Nbr Véhicule",
          sortable: true,
          class: "text-center",
        },

      ],
      manifestes: [],
      ExcelManifestes: null,
      nameState: null,
      editedDate: {
        id: "",
        date_arrivee: "",
      },
      errorsDateArrivalModal: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),

    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);
      return maxDate;
    },

    totalRows() {
      // Set the initial number of items
      return this.manifestes.length;
    },
  },
  created() {
    this.getManifestes();
    this.getImportateurs();
    this.selectedTrie = "decroissant"
  },
  mounted() {},
  methods: {
    resetModalEditManifeste() {
      this.editedDate.date_arrivee = "";
      this.nameState = null;
      this.resetInputDate();
    },

    resetInputDate() {
      if (this.errorsDateArrivalModal) {
        this.errorsDateArrivalModal = null;
      }
    },

    changeTrie() {
      this.lastmanifestes = this.manifestes
      if(this.selectedTrie == "croissant") {
        this.manifestes = this.manifestes.reverse();
      }
      if(this.selectedTrie == "decroissant") {
      this.manifestes = this.manifestes.reverse();
      }
    },

    getImportateurs() {
      this.$http
        .get("/show-importateurs")
        .then((res) => {
          res.data.data.reverse().forEach((element) => {
            var obj = {
              value: element.id,
              text: element.name,
            };

            this.options.push(obj);
          });
        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },

    dateFiltre() {
      if (this.errorValueStart) {
        this.errorValueStart = "";
      }

      if (this.valueStart) {
        let datePicker = {
          from: this.valueStart,
          to: this.valueEnd,
          imp: "",
        };

        if (
          this.selectedImportateur &&
          this.selectedImportateur != "-- Importateurs --"
        ) {
          datePicker.imp = this.selectedImportateur;
        }

        this.$http
          .post("/carte-importateur/filtreDateManifeste", datePicker)
          .then((res) => {
            if (res.data.success) {
              this.manifestes = res.data.data.reverse();
            }
          })
          .catch((e) => {
            console.log(e.response);
          });
      } else if (!this.valueStart && this.valueEnd) {
        this.errorValueStart = "choisissez une date de début !";
      } else {
        if (
          this.selectedImportateur &&
          this.selectedImportateur != "-- Importateurs --"
        ) {
          this.importateurFiltre();
        } else {
          this.getManifestes();
        }
      }
    },
    onRowClicked(item, index, event) {
      window.sessionStorage.setItem("manifeste_id", item.id);
      this.$router.push({
        name: "manifeste-details",
      });
    },

    importateurFiltre() {
      if (
        this.selectedImportateur &&
        this.selectedImportateur != "-- Importateurs --"
      ) {
        let importateur = {
          imp: this.selectedImportateur,
          from: "",
          to: "",
        };

        if (this.valueStart) {
          importateur.from = this.valueStart;
          importateur.to = this.valueEnd;
        }

        this.$http
          .post("/carte-importateur/filtreImportateurManifeste", importateur)
          .then((res) => {
            if (res.data.success) {
              this.manifestes = res.data.data.reverse();
            }
          })
          .catch((e) => {
            console.log(e.response);
          });
      } else {
        if (this.valueStart) {
          this.dateFiltre();
        } else {
          this.getManifestes();
        }
      }
    },

    getManifestes() {
      this.$http
        .get("/carte-importateur/manifestes")
        .then((res) => {
          if (res.status) {
            this.hiddenLoader = true;
          }

          this.manifestes = res.data.data.reverse();

          this.manifestes.sort(function(a,b){
            return new Date(b.date_arrivee) - new Date(a.date_arrivee);
          });
          this.manifestes.reverse()

          if (this.manifestes.length) {
            this.hidden = false;
            this.hiddenEmpty = true;
          } else {
            this.hidden = true;
            this.hiddenEmpty = false;
          }
        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },

    checkFormValidity() {
      const valid = this.$refs.editForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    handleSubmit() {
      // this.$refs.editForm.validate().then((success) => {
      // 	console.log(success)
      // 	if (success) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.etatButton = false;

      if (!this.editedDate.date_arrivee) {
        this.errorsDateArrivalModal = "Veuillez sélectionner une date !";
        this.etatButton = true;
      } else {
        this.$http
          .put(
            "/receptionner-manifeste-ci/" + this.editedDate.id,
            this.editedDate
          )
          .then((result) => {
            if (result.data.success) {
              this.etatButton = true;
              this.editedDate = JSON.parse(
                JSON.stringify({ type: Object, default: () => {} })
              );
              this.modalEditManifeste = false;
              this.showToast("Date enregistré avec succès", "success");
              this.getManifestes();
            } else {
              this.etatButton = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButton = true;
            console.log("erreur d'ajout date:" + e);
            var err = e.response.data.errors;

            if (err) {
              if (err.date_arrivee) {
                this.errorsDateArrivalModal = err.date_arrivee[0];
              }
            } else if (e.response.status == 404) {
              this.editedDate = JSON.parse(
                JSON.stringify({ type: Object, default: () => {} })
              );
              this.modalEditManifeste = false;
              this.showToast("Date enregistré avec succès", "success");
              this.getManifestes();
            }
          });
      }
      // 	}
      // });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.handleSubmit();
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    addsManifeste() {
      this.$router.push({
        name: "manifeste-add",
      });
    },

    editManifeste(item) {
      this.editedDate.id = item.id;
      this.modalEditManifeste = true;
    },

    deleteManifeste(item) {
      // Message de suppression
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet enregistrement de la liste des manifestes ?",
          {
            title: "Supprimer une manifeste",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http
              .delete("/carte-importateur/manifestes/" + item.id)
              .then((result) => {
                if (result.data.success) {
                  this.showToast(
                    "Suppression effectuée avec succès",
                    "success"
                  );
                  this.getManifestes();
                } else {
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((err) => {
                this.showToast(err.response, "error");
              });
          }
        });
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.ExcelManifestes.forEach((element) => {
        obj = {
          "NUMERO(S)": element.numero_manif,
          "DATE RECEPTION(S)": element.date_arrivee,
          "IMPORTATEUR(S)": element.importateur.name,
          "NAVIRE(S)": element.navire.name,
          "TOTAL CARTE IMPORTATEUR(S)": element.prix_carte_imp
            ? element.prix_carte_imp
            : 0,
          "NBR VEHICULE(S)": element.nbr_vehicules ? element.nbr_vehicules : 0,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des manifestes",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },


    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.manifestes.forEach((element) => {
        obj = {
          "NUMERO(S)": element.numero_manif,
          "DATE RECEPTION(S)": element.date_arrivee,
          "IMPORTATEUR(S)": element.importateur.name,
          "NAVIRE(S)": element.navire.name,
          "TOTAL CARTE IMPORTATEUR(S)": element.prix_carte_imp
            ? element.prix_carte_imp
            : 0,
          "NBR VEHICULE(S)": element.nbr_vehicules ? element.nbr_vehicules : 0,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des manifestes",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

  },
};
</script>

<style lang="scss">
	#loader {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #f8f8f8;
		padding-top: 15%;
		z-index: 1000;
	}

	.b-form-date-controls {
		margin-right: 38%;
	}

	@media screen and (max-width: 700px) {
		.container-filter {
			display: block !important;
		}

		.container-search-manifest-impor, 
		.container-date-start, .container-date-end, 
		.container-importateur {
			width: 100% !important;
		}

		.container-search-manifest-impor, .container-date-end {
			margin-bottom: 20px !important;
		}

		.container-importateur {
			max-width: 100% !important;
		}
	}
</style>
